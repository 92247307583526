@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
body {
  margin: 0;
  padding: 0;
  color: #2a3a56;
  background-color: #f6f6f6;
  font-family: "Open Sans", sans-serif;
}
body a {
  transition: all 0.3s;
}
body a:hover {
  opacity: 0.5;
}
body .section {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border-radius: 0.35rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  padding: 2em;
  border: 1px solid #d8e1f5;
}
body a {
  text-decoration: none;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  color: #122048;
}
body div.ui.attached.tabular.menu {
  margin-bottom: 1em;
}
body .dashboard {
  padding: 2em 1em;
}
body #add-trader {
  display: grid;
  grid-template-columns: 1fr 70px;
  align-items: end;
  gap: 1em;
  margin-bottom: 1em;
}
body #add-trader .watchlist-counter {
  float: right;
  font-size: 16px;
  padding: 0 10px 0 0;
  color: #999;
  text-align: right;
}
body #notification {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}
body #notification h2 {
  grid-column: span 3;
  margin: 0;
}
body #notification .section {
  padding: 1.5em 2em;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
body #notification .section h3 {
  margin-bottom: 2em;
  flex-grow: 1;
  width: 100%;
  align-self: baseline;
}
body #notification .section .controls {
  flex-grow: 1;
  width: 100%;
  align-self: baseline;
}
body #notification .section .description {
  border-top: 1px solid #bdc7dd;
  padding-top: 1em;
  margin-top: 2em;
  font-size: 12px;
  color: #374557;
  display: inline-block;
  align-self: flex-end;
  flex-grow: 1;
  width: 100%;
}
body div#trader-profile .section {
  margin: 1em 0 3em 0;
}
body div#trader-profile .performance .title {
  font-size: 16px;
  font-weight: 600;
  color: #576893;
}
body div#trader-profile .performance .title:nth-child(3) {
  margin-top: 2em;
}
body div#trader-profile .performance .index {
  font-size: 26px;
  margin: 0.5em 0;
  font-weight: 600;
}
body div#trader-profile .performance .negative {
  color: crimson;
}
body div#trader-profile .performance .positive {
  color: forestgreen;
}
body div#trader-profile section#basic-info {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr;
  gap: 2em;
  align-items: center;
}
body div#trader-profile section#basic-info div.avatar img {
  max-width: 150px;
  border-radius: 100%;
  border: 3px solid #4d68ae;
}
body div#trader-profile section#basic-info div.info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
}
body div#trader-profile section#basic-info div.info h2 {
  margin: 0;
}
body div#trader-profile section#basic-info div.info h2 a {
  padding-right: 20px;
  position: relative;
}
body div#trader-profile section#basic-info div.info h2 a i {
  font-size: 18px;
  color: #122048;
  position: absolute;
  top: 1px;
  right: -5px;
}
body div#trader-profile section#basic-info div.info button {
  max-width: 100px;
}
body div#trader-profile section#basic-info .performance {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}
body div#trader-profile section#basic-info .buttons {
  justify-self: end;
}
body div#trader-profile #data-overview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2em;
  align-items: center;
  justify-items: center;
  padding: 3em 2em;
}
body div#trader-profile #data-overview.exact-period {
  grid-template-columns: 1fr 1fr 1fr;
}
body div#trader-profile #data-overview h2 {
  grid-column: span 4;
  justify-self: baseline;
}

.loading-box {
  min-height: 320px;
}

#public > section {
  padding: 5em 0;
}
#public > section h2 {
  font-size: 42px;
}

#account {
  max-width: 960px;
  margin: 0 auto;
}
#account .subscription {
  text-align: right;
  font-weight: 600;
  margin-bottom: 2em;
}
#account .subscription i.check.icon {
  color: forestgreen;
}
#account .subscription i.close.icon {
  color: crimson;
}
#account .subscription.not span {
  background: #f3dfdf;
  color: crimson;
}
#account .subscription span {
  padding: 6px 10px;
  border-radius: 6px;
  background: #c9ec96;
  color: #000;
  margin-left: 5px;
}

#billing section.plan {
  margin-bottom: 1em;
}
#billing section.plan .divider {
  min-width: 260px;
}
#billing section.plan button {
  margin: 0 0 0 10px;
}
#billing .table {
  min-height: 400px;
}
#billing .table .row {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 120px;
}
#billing .table .row .pnl span.sum {
  min-width: 100px;
}

#traders section.add-trader-form .message {
  max-width: 100%;
}
#traders .table {
  min-height: 400px;
}
#traders .table .row {
  grid-template-columns: 50px 1fr 1fr 1fr 1fr;
}
#traders .table .row .pnl span.sum {
  min-width: 100px;
}

#actions .table .row {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px 1fr;
}
#actions .total-profit {
  margin-top: 2em;
  color: #969dab;
}

#positions {
  min-height: 400px;
}

.symbol {
  padding-left: 1em;
  font-weight: 600;
}
.symbol span {
  margin-right: 10px;
}
.symbol .ticker {
  min-width: 80px;
  display: inline-block;
}
.symbol .direction, .symbol .leverage {
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
}
.symbol .long {
  background: forestgreen;
}
.symbol .short {
  background: crimson;
}
.symbol .leverage {
  background: #facc44;
  color: #000;
}

.table {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  align-content: baseline;
}
.table .header.row {
  border-bottom: 2px solid #798691;
  font-weight: 600;
  color: #798691;
  align-self: baseline;
}
.table .header.row .btn .search {
  border-radius: 5px;
}
.table .header.row .btn .search .react-dropdown-select-input {
  width: 100%;
}
.table .header.row .btn .search .react-dropdown-select-option-label {
  font-size: 12px;
}
.table .header.row .btn .search .react-dropdown-select-option-remove {
  font-size: 16px;
}
.table .row {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
  padding: 0.7em 0;
  border-bottom: 1px solid #b7c6d5;
  gap: 1em;
}
.table .body.row:last-child {
  border-bottom: none;
}
.table .body.row.negative {
  background: #fff6f6;
}
.table .body.row.negative .pnl {
  color: crimson;
}
.table .body.row.negative .pnl .roe {
  background: crimson;
}
.table .body.row .symbol .direction, .table .body.row .symbol .leverage {
  font-size: 12px;
}
.table .body.row .name {
  font-weight: 600;
  font-size: 16px;
}
.table .body.row .name a {
  margin-right: 10px;
}
.table .body.row .name .orders {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #888;
}
.table .body.row .orders-count {
  text-align: center;
  font-weight: 600;
  color: #506488;
}
.table .body.row .btn {
  justify-self: end;
  padding-right: 1em;
}
.table .body.row div {
  overflow: hidden;
}

.pnl {
  color: forestgreen;
  font-weight: 600;
}
.pnl span {
  margin-right: 10px;
}
.pnl .roe {
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 12px;
  background: forestgreen;
}
.pnl .sum {
  min-width: 70px;
  display: inline-block;
}
.pnl.negative {
  color: crimson;
}
.pnl.negative .roe {
  background: crimson;
}

#footer {
  min-height: 320px;
  background: radial-gradient(94% 58.3% at 50.3% 82.6%, hsl(252, 28%, 20%) 0%, var(--token-79f54f4b-5c08-4adb-84cd-68f4d833bf59, rgb(17, 16, 19)) 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  padding: 6em 10px;
}
#footer .midland {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
#footer .midland > div {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  align-content: baseline;
}
#footer .midland > div a {
  color: #cccccc;
  font-weight: 600;
}
#footer .midland h3 {
  color: #ffcbad;
}

div.ui.message.custom {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 0 0.8em;
  align-content: center;
  align-items: center;
}
div.ui.message.custom i.icon {
  grid-row: span 2;
  font-size: 3em;
  align-self: center;
  line-height: 1;
}

#faq {
  margin: 4em 0;
}

@media only screen and (max-width: 768px) {
  body .section {
    padding: 0.5em;
  }
  body .dashboard {
    padding: 2em 0.5em;
  }
  body #notification {
    grid-template-columns: 1fr;
  }
  body #notification h2 {
    grid-column: inherit;
  }
  body #add-trader {
    grid-template-columns: 1fr;
  }
  body #add-trader button {
    width: 100%;
    margin: 8px;
  }
  body #traders .table {
    overflow-x: scroll;
  }
  body #traders .table .row {
    grid-template-columns: 50px 1fr 1fr;
    min-width: 360px;
  }
  body #billing .table {
    overflow-x: scroll;
  }
  body #billing .table .row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 70px;
    min-width: 520px;
  }
  body div#trader-profile .performance .title {
    font-size: 14px;
  }
  body div#trader-profile .performance .index {
    font-size: 18px;
  }
  body div#trader-profile section#basic-info {
    grid-template-columns: 1fr;
  }
  body div#trader-profile section#basic-info .buttons {
    width: 100%;
  }
  body div#trader-profile #data-overview {
    align-items: baseline;
    justify-items: baseline;
    grid-template-columns: 1fr 1fr;
  }
  body div#trader-profile #data-overview.exact-period {
    grid-template-columns: 1fr 1fr;
  }
  body #positions {
    min-height: 400px;
  }
  body #positions .table {
    overflow-x: scroll;
  }
  body #positions .table .btn.search {
    grid-column: span 6;
  }
  body #positions .table .row {
    min-width: 720px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  body #actions .table .row {
    min-width: 510px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px 1fr;
  }
  body #billing section.plan .ui.selection.dropdown {
    width: 100%;
  }
  body #billing section.plan .divider {
    min-width: 100%;
  }
  body #billing section.plan button {
    width: 100%;
    margin: 10px 0 0 0;
  }
  body .table .body.row .name {
    font-weight: 600;
    font-size: 14px;
  }
  body .table {
    overflow-x: scroll;
  }
  body .table .row {
    gap: 0.5em;
    font-size: 12px;
  }
  body .table .row .no-mob {
    display: none;
  }
  body #footer .midland {
    grid-template-columns: 1fr 1fr;
  }
  body #footer .midland > div {
    margin-bottom: 4em;
  }
}

