div#public a.btn, #footer a.btn {
  text-transform: uppercase;
  border: 2px solid #db9818;
  padding: 15px 20px;
  border-radius: 8px;
  background: #1c0537;
  color: #fff;
  display: inline-block;
  margin: 1em;
  cursor: pointer;
}
div#public .midland, #footer .midland {
  width: 100%;
  max-width: 990px;
  margin: 0 auto;
}
div#public .midland.center, #footer .midland.center {
  text-align: center;
}
div#public .midland .context, #footer .midland .context {
  margin: 40px 0;
  padding: 0 10px;
}
div#public .midland .context:last-child, #footer .midland .context:last-child {
  margin-bottom: 0;
}
div#public .midland.mxw-920, #footer .midland.mxw-920 {
  max-width: 920px;
}
div#public .midland.mxw-1124, #footer .midland.mxw-1124 {
  max-width: 1124px;
}

div#public.index h2 span {
  color: #814ac8;
}
div#public.index .darkness h1 {
  font-size: 62px;
  color: bisque;
}
div#public.index .darkness h1 span {
  color: orange;
}
div#public.index .darkness h3 {
  color: aliceblue;
  font-size: 28px;
}
div#public.index .darkness h3 span {
  color: lightsalmon;
}
div#public.index .darkness a.btn {
  border: 2px solid #db9818;
  background: #1c0537;
  color: #fff;
}
div#public.index .darkness a.btn i {
  color: #db9818;
}
div#public.index .darkness a.btn span {
  color: #db9818;
}
div#public.index .darkness a.btn.b2 {
  border: 2px solid #494ace;
}
div#public.index .darkness a.btn.b2 i {
  color: #494ace;
}
div#public.index #top {
  padding-top: 200px;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  flex: 0 0 auto;
  background: radial-gradient(94% 58.3% at 50.3% 82.6%, #2a2541 0%, var(--token-79f54f4b-5c08-4adb-84cd-68f4d833bf59, rgb(10, 3, 24)) 100%);
}
div#public.index #about {
  min-height: 300px;
  background-color: #fff6f6;
}
div#public.index #about .context {
  font-size: 21px;
}
div#public.index #about .context p:last-child {
  margin: 0;
}
div#public.index #about .context p {
  margin-bottom: 30px;
}
div#public.index #about .context h3 {
  margin-bottom: 30px;
  color: #510660;
  font-size: 30px;
}
div#public.index #how-to-use .context {
  text-align: justify;
  font-size: 21px;
  display: grid;
  gap: 3em;
  grid-template-columns: 1fr 1fr;
}
div#public.index #how-to-start {
  position: relative;
  overflow: visible;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  background: radial-gradient(94% 92.9% at 100% 96.1%, #413025 0%, var(--token-79f54f4b-5c08-4adb-84cd-68f4d833bf59, rgb(17, 16, 19)) 100%);
  color: #ffffff;
}
div#public.index #how-to-start h2 {
  font-size: 52px;
  text-align: center;
  color: #ffffff;
}
div#public.index #how-to-start h2 span {
  color: #ffcbad;
}
div#public.index #how-to-start .context {
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 20px;
}
div#public.index #how-to-start .context li {
  margin: 20px 0;
}
div#public.index .grid.double {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2em;
  text-align: left;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  div#public.index #how-to-use .context {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 580px) {
  div#public.index .grid.double {
    grid-template-columns: 1fr;
  }
  div#public.index .darkness h1 {
    font-size: 40px;
  }
  div#public.index .darkness h3 {
    font-size: 22px;
  }
}

#dashboard.leaderboard {
  max-width: 1760px;
  margin: 0 auto;
}
#dashboard.leaderboard .leaders {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2em 1.5em;
  min-height: 280px;
  position: relative;
}
#dashboard.leaderboard .leaderboard-description {
  font-size: 16px;
  max-width: 960px;
  margin-bottom: 3em;
}
#dashboard.leaderboard .trader-card {
  padding: 15px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #fff;
}
#dashboard.leaderboard .trader-card .header {
  display: grid;
  grid-template-columns: 1fr 90px;
}
#dashboard.leaderboard .trader-card .header .name {
  color: #777;
}
#dashboard.leaderboard .trader-card .header .name a {
  font-weight: bold;
}
#dashboard.leaderboard .trader-card .header .name .avatar {
  float: left;
  margin-right: 10px;
  position: relative;
}
#dashboard.leaderboard .trader-card .header .name .avatar img.binance-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 26px;
  background: #0c005b;
}
#dashboard.leaderboard .trader-card .header .name .avatar img {
  width: 70px;
  border-radius: 100%;
  border: 3px solid #0c005b;
  background: #0c005b;
}
#dashboard.leaderboard .trader-card .data {
  margin-top: 20px;
}
#dashboard.leaderboard .trader-card .data .pnl {
  width: 50%;
  float: left;
}
#dashboard.leaderboard .trader-card .data .pnl h4 {
  margin-bottom: 5px;
  color: #777;
}
@media only screen and (max-width: 1600px) {
  #dashboard.leaderboard .leaders {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 1180px) {
  #dashboard.leaderboard .leaders {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 790px) {
  #dashboard.leaderboard .leaders {
    grid-template-columns: 1fr;
  }
}

