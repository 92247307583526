.auth {
  max-width: 560px;
  margin: 6em auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
}

.auth.section {
  padding: 25px;
}
.auth.section h1, .auth.section h2, .auth.section h3, .auth.section h4, .auth.section h5, .auth.section h6 {
  margin: 0;
}

