@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  color: #2a3a56;
  background-color: #f6f6f6;
  font-family: 'Open Sans', sans-serif;

  a {
    transition: all 0.3s;
  }

  a:hover {
    opacity: 0.5;
  }

  .section {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border-radius: 0.35rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    padding: 2em;
    border: 1px solid #d8e1f5;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6{
    color: #122048;
  }

  h1{

  }
  h2{

  }
  h3{

  }

  div.ui.attached.tabular.menu{
    margin-bottom: 1em;
  }

  .dashboard{
    padding: 2em 1em;
  }

  #add-trader{
    display: grid;
    grid-template-columns: 1fr 70px;
    align-items: end;
    gap: 1em;
    margin-bottom: 1em;

    .watchlist-counter {
      float: right;
      font-size: 16px;
      padding: 0 10px 0 0;
      color: #999;
      text-align: right;
    }
  }

  #notification{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;

    h2 {
      //grid-area: 1 / 1 / 2 / 4;
      grid-column: span 3;
      margin: 0;
    }

    .section{
      padding: 1.5em 2em;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      h3{
        margin-bottom: 2em;
        flex-grow: 1;
        width: 100%;
        align-self: baseline;
      }

      .controls{
        flex-grow: 1;
        width: 100%;
        align-self: baseline;
      }

      .description {
        border-top: 1px solid #bdc7dd;
        padding-top: 1em;
        margin-top: 2em;
        font-size: 12px;
        color: #374557;
        display: inline-block;
        align-self: flex-end;
        flex-grow: 1;
        width: 100%;
      }
    }

  }


  div#trader-profile{

    .section {
      margin: 1em 0 3em 0;
    }

    .performance{
      .title{
        font-size: 16px;
        font-weight: 600;
        color: #576893;

        &:nth-child(3){
          margin-top: 2em;
        }

      }
      .index{
        font-size: 26px;
        margin: 0.5em 0;
        font-weight: 600;
      }

      .negative {
        color: crimson;
      }
      .positive {
        color: forestgreen;
      }
    }

    section#basic-info{
      display: grid;
      grid-template-columns: 150px 1fr 1fr 1fr;
      gap: 2em;
      align-items: center;

      div.avatar{
        img{
          max-width: 150px;
          border-radius: 100%;
          border: 3px solid #4d68ae;
        }
      }

      div.info{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        h2{
          margin: 0;

          a {
            padding-right: 20px;
            position: relative;
            i {
              font-size: 18px;
              color: #122048;
              position: absolute;
              top: 1px;
              right: -5px;
            }
          }

        }
        button{
          max-width: 100px;
        }
      }

      .performance{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
      }

      .buttons{
        justify-self: end;
      }

    }


    #data-overview {

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 2em;
      align-items: center;
      justify-items: center;
      padding: 3em 2em;

      &.exact-period{
        grid-template-columns: 1fr 1fr 1fr;
      }

      h2{
        grid-column: span 4;
        justify-self: baseline;
      }

    }

  }

}

.loading-box {
  min-height: 320px;
}

#public {
  > section {
    h2 {
      font-size: 42px;
    }
    padding: 5em 0;
  }
}

#account {
  max-width: 960px;
  margin: 0 auto;

  .subscription {
    text-align: right;
    font-weight: 600;
    margin-bottom: 2em;
    i.check.icon{
      color: forestgreen;
    }
    i.close.icon{
      color: crimson;
    }
    &.not{
      span{
        background: #f3dfdf;
        color: crimson;
      }
    }
    span {
      padding: 6px 10px;
      border-radius: 6px;
      background: #c9ec96;
      color: #000;
      margin-left: 5px;
    }
  }

}

#billing {

  section.plan{
    margin-bottom: 1em;
    .divider{
      min-width: 260px;
    }
    button{
      margin: 0 0 0 10px;
    }
  }

  .table {
    min-height: 400px;
    .row {
      grid-template-columns: 1fr 1fr 1fr  1fr 1fr 1fr 120px;
      .pnl span.sum{
        min-width: 100px;
      }
    }
  }
}

#traders {
  section.add-trader-form .message{
    max-width: 100%;
  }
  .table {
    min-height: 400px;
    .row {
      grid-template-columns: 50px 1fr 1fr 1fr 1fr;
      .pnl span.sum{
        min-width: 100px;
      }

    }
  }
}

#actions {
  .table {
    .row {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px 1fr;
    }
  }
  .total-profit{
    margin-top: 2em;
    color: #969dab;
  }
}

#positions{
  min-height: 400px;
}

.symbol {
  padding-left: 1em;
  font-weight: 600;

  span {
    margin-right: 10px;
  }

  .ticker {
    min-width: 80px;
    display: inline-block;
  }

  .direction, .leverage {
    color: #fff;
    padding: 2px 5px;
    border-radius: 4px;
  }

  .long {
    background: forestgreen;
  }

  .short {
    background: crimson;
  }

  .leverage {
    background: #facc44;
    color: #000;
  }
}

.table {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  align-content: baseline;

  .header.row {
    border-bottom: 2px solid #798691;
    font-weight: 600;
    color: #798691;
    align-self: baseline;

    .btn {
      .search {
        border-radius: 5px;
        .react-dropdown-select-input{
          width: 100%;
        }
        .react-dropdown-select-option-label{
          font-size: 12px;
        }
        .react-dropdown-select-option-remove{
          font-size: 16px;
        }
      }
    }

  }

  .row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    align-items: center;
    padding: 0.7em 0;
    border-bottom: 1px solid #b7c6d5;
    gap: 1em;
  }

  .body.row {

    &:last-child {
      border-bottom: none;
    }

    &.negative {
      background: #fff6f6;

      .pnl {
        .roe {
          background: crimson;
        }

        color: crimson;
      }
    }

    .symbol {
      .direction, .leverage {
        font-size: 12px;
      }
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      a {
        margin-right: 10px;
      }
      .orders {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #888;
      }
    }

    .orders-count{
      text-align: center;
      font-weight: 600;
      color: #506488;
    }

    .btn {
      justify-self: end;
      padding-right: 1em;
    }
    div {
      overflow: hidden;
    }
  }

}

.pnl {
  color: forestgreen;
  font-weight: 600;

  span {
    margin-right: 10px;
  }

  .roe {
    color: #fff;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    background: forestgreen;
  }

  .sum {
    min-width: 70px;
    display: inline-block;
  }

  &.negative{
    .roe {
      background: crimson;
    }

    color: crimson;
  }

}

#footer{

  min-height: 320px;
  background: radial-gradient(94% 58.3% at 50.3% 82.6%, hsl(252, 28%, 20%) 0%, var(--token-79f54f4b-5c08-4adb-84cd-68f4d833bf59, rgb(17, 16, 19)) 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  padding: 6em 10px;
  .midland{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    > div{
      display: grid;
      grid-template-columns: 1fr;
      gap: 1em;
      align-content: baseline;
      a{
        color: #cccccc;
        font-weight: 600;
      }
    }
    h3{
      color: #ffcbad;
    }
  }
}

div.ui.message.custom {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 0 .8em;
  align-content: center;
  align-items: center;
  i.icon {
    grid-row: span 2;
    font-size: 3em;
    align-self: center;
    line-height: 1;
  }

}

#faq {
  margin: 4em 0;
}

@media only screen and (max-width: 768px)  {

body {

  .section {
    padding: 0.5em;
  }

  .dashboard {
    padding: 2em 0.5em;
  }

  #notification {
    grid-template-columns: 1fr;

    h2 {
      grid-column: inherit;
    }
  }

  #add-trader{

    grid-template-columns: 1fr;

    button{
      width: 100%;
      margin: 8px;
    }

    .watchlist-counter {

    }
  }

  #traders {
    .table {
      overflow-x: scroll;
      .row {
        grid-template-columns: 50px 1fr 1fr;
        min-width: 360px;
      }
    }

  }

  #billing {
    .table {
      overflow-x: scroll;
      .row {
        grid-template-columns: 1fr 1fr 1fr  1fr 1fr 70px;
        min-width: 520px;
      }
    }
  }

  div#trader-profile{
    .performance .title {
      font-size: 14px;
    }
    .performance .index {
      font-size: 18px;
    }


    section#basic-info {
      grid-template-columns: 1fr;
      .buttons {
        width: 100%;
      }
    }

    #data-overview {
      align-items: baseline;
      justify-items: baseline;
      grid-template-columns: 1fr 1fr;
      &.exact-period{
        grid-template-columns: 1fr 1fr;
      }
    }

  }

  #positions{
    min-height: 400px;
    .table {
      overflow-x: scroll;
      .btn.search{
        grid-column: span 6;
      }
      .row {
        min-width: 720px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }

  #actions {
    .table {
      .row {
        min-width: 510px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px 1fr;
      }
    }
  }

  #billing section.plan {
    .ui.selection.dropdown{
      width: 100%;
    }
    .divider {
      min-width: 100%;
    }
    button{
      width: 100%;
      margin: 10px 0 0 0;
    }
  }

  .table .body.row .name {
    font-weight: 600;
    font-size: 14px;
  }

  .table{
    overflow-x: scroll;
    .row{
      gap: 0.5em;
      font-size: 12px;
      .no-mob{
        display: none;
      }
    }
  }

  #footer{
    .midland{
      grid-template-columns: 1fr 1fr;
      > div {
        margin-bottom: 4em;
      }
    }
  }

}

}