.auth {
  max-width: 560px;
  margin: 6em  auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
}

.auth.section{
  padding: 25px;
  h1, h2, h3, h4, h5, h6{
    margin: 0;
  }
}