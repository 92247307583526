#header {
  background: rgb(18, 6, 42);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  min-height: 80px;
  padding: 5px 1em;
  display: grid;
  grid-template-columns: 256px auto;
  align-content: center;
  justify-content: normal;
  align-items: center;
  justify-items: end;
  gap: 1em;
}
#header.transparent {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background: transparent;
}
#header #logo img {
  height: 80px;
}
#header #logo:hover {
  opacity: 0.6;
}
#header #right-nav a {
  padding: 0 15px;
  color: aliceblue;
}
#header #right-nav a.active {
  color: goldenrod;
}
#header #right-nav button {
  margin: 0 0 0 15px;
}
#header nav.navi.mobile {
  display: none;
}

@media only screen and (max-width: 900px) {
  body {
    margin: 65px 0 0 0 !important;
  }
  #header {
    grid-template-columns: 150px auto;
    min-height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  #header #logo img {
    height: 50px;
  }
  #header nav.navi.mobile {
    display: block;
  }
  #header nav#right-nav.navi {
    transition: all 0.3s;
    position: fixed;
    top: 65px;
    background: #1c0d39;
    height: 100%;
    z-index: 999;
    right: -200%;
    width: 60%;
    min-width: 320px;
    display: grid;
    grid-template-columns: 1fr;
    align-content: baseline;
  }
  #header nav#right-nav.navi.opened {
    right: 0;
  }
  #header nav#right-nav.navi a {
    padding: 12px 15px;
    border-bottom: 1px solid #2f1660;
  }
  #header nav#right-nav.navi a:last-child {
    border-bottom: none;
  }
}

